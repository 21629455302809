import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ProjectHeader from "../components/project-header"
import ProjectBlurb from "../components/project-blurb"

const info = {
    duration: "2014 - 2015",
    references: [
        {
            head: "Engineering Reference",
            name: "Mark Napier, P.E.",
            employer: "JUB",
            phone: "(509) 458 3727",
        },
        {
            head: "Client Reference",
            name: "Paul Schmidt",
            title: "City Administrator",
            employer: "City of Des Moines",
            phone: "(509) 682 8014",
        },
    ],
}

const Page = () => {
    const data = useStaticQuery(graphql`
      query {
        header: file(relativePath: { eq: "project-header--chelan-airport.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mapp: file(relativePath: { eq: "map--chelan-airport.png" }) {
          childImageSharp {
            fluid(maxWidth: 680) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
    return (
        <Layout hideMenu={true}>
          <SEO title="Chelan Airport Improvement" />
          <ProjectHeader
            client="Lake Chelan Airport"
            project="Airport Improvement"
            image={data?.header?.childImageSharp?.fluid}
          />
          <ProjectBlurb data={info} mapImage={data.mapp.childImageSharp.fluid}>
            <p>
              This project consist of the acquisition of land to
              comply with FAA Oder 5100.38C, which requires airports
              to ensure compatible land use of runway approach and
              transitional zones for flight safety.  Ms. Guzman was
              responsible for 1 business relocation, 1 residential
              relocation, and 2 personal property only relocations.
              RES Group NW provided relocation plan with budget and
              completed the relocation on time. Relocation
              complexities included undocumented workers, carve-out of
              business from home, and displacee that required ADA
              accommodations.
            </p>
          </ProjectBlurb>
        </Layout>
    )
}

export default Page
